
import axios from 'axios';

const contentModule = {

state: {

songsData: {},
videosData: {},
songDatabaseId: null,
videoDatabaseId: null,
editingSongIndex: null,
editingVideoIndex: null,
adminVerificationCase: null,
verificationModal: false,
toggleEditing: false,
isUploading: false,
uploadPercent: 0,

},

mutations: {

updateSongsData (state, payload) {
state.songsData = payload;
},

updateVideosData (state, payload) {
state.videosData = payload;
},

toggleEditing (state) {
state.toggleEditing = !state.toggleEditing;
},

isUploading (state) {
state.isUploading = !state.isUploading;
},

uploadPercent (state, percent) {
state.uploadPercent = percent;
},

},


getters: {

songsData (state) {
return state.songsData;
},

videosData (state) {
return state.videosData;
},

verificationModal (state) {
return state.verificationModal;
},

adminVerificationCase (state) {
return state.adminVerificationCase;
},

songDatabaseId (state) {
return state.songDatabaseId;
},

videoDatabaseId (state) {
return state.videoDatabaseId;
},

editingSongIndex (state) {
return state.editingSongIndex;
},

editingVideoIndex (state) {
return state.editingVideoIndex;
},

toggleEditing (state) {
return state.toggleEditing;
},

isUploading (state) {
return state.isUploading;
},

uploadPercent (state) {
return state.uploadPercent;
},

},

actions: {

async getSongsData({ commit }) {

try {

const responseResult = await axios.get(process.env.VUE_APP_BASE_URL + '/Api/Content/Songs/');

if (responseResult.data.operation == 'success') {

commit("updateSongsData", responseResult.data.songsData);

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

}

} catch (error) {
throw new Error(error);
}

},


async addNewSongToDB({ commit }, payload) {

try {

commit('showLoadingSpinner');
commit("toggleBlur");
commit("isUploading");

const responseResult = await axios({
method: "post",  
headers: { "Content-Type": "multipart/form-data" },
data: payload.songData,
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Songs/Add/`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit("isUploading");
commit('hideLoadingSpinner');
commit("toggleBlur");

if (responseResult.data.operation == 'success') {

commit("updateSongsData", responseResult.data.updatedSongsData);

commit("showModal", {
modalMessageEnglish: 'The new song has been added',
adminVerificationCase: 'AddNewSong',
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to add song",
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-song') {

commit("showModal", {
modalMessageEnglish: "The audio you selected is used for different song, please select a unique one",
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-cover') {

commit("showModal", {
modalMessageEnglish: "The photo you selected is used for different song, please select a unique one",
informationalModal: true,
});

return responseResult.data.operation;

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingSongIndex: null
});

}

} catch (error) {
throw new Error(error);
}

},


async updateSongDataToDB({ commit, state }, payload) {

try {

commit('showLoadingSpinner');
commit("toggleBlur");
commit("isUploading");

const responseResult = await axios({
method: "post",  
headers: { "Content-Type": "multipart/form-data" },
data: payload.songData,
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Songs/Edit/${state.songDatabaseId}`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit("isUploading");
commit('hideLoadingSpinner');
commit("toggleBlur");

if (responseResult.data.operation == 'success') {

commit("updateSongsData", responseResult.data.updatedSongsData);

commit("showModal", {
modalMessageEnglish: 'The changes have been saved',
informationalModal: true,
editingSongIndex: state.editingSongIndex
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-song') {

commit("showModal", {
modalMessageEnglish: "The audio you selected is used for different song, please select a unique one",
informationalModal: true,
editingSongIndex: state.editingSongIndex
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-cover') {

commit("showModal", {
modalMessageEnglish: "The photo you selected is used for different song, please select a unique one",
informationalModal: true,
editingSongIndex: state.editingSongIndex
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to save",
informationalModal: true,
editingSongIndex: state.editingSongIndex
});

return responseResult.data.operation;

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingSongIndex: null
});

}

} catch (error) {
throw new Error(error);
}

},



async deleteSongFromDB({ commit, state }) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "delete",  
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Songs/Delete/${state.songDatabaseId}`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == 'success') {

commit("updateSongsData", responseResult.data.updatedSongsData);

commit('hideModal');

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete",
informationalModal: true,
editingSongIndex: state.editingSongIndex
});

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingSongIndex: null
});

}

} catch (error) {
throw new Error(error);
}

},


async getVideosData({ commit }) {

try {

const responseResult = await axios.get(process.env.VUE_APP_BASE_URL + '/Api/Content/Videos/');

if (responseResult.data.operation == 'success') {

commit("updateVideosData", responseResult.data.videosData);

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
});

}

} catch (error) {
throw new Error(error);
}

},


async addNewVideoToDB({ commit }, payload) {

try {

commit('showLoadingSpinner');
commit("toggleBlur");
commit("isUploading");

const responseResult = await axios({
method: "post",  
headers: { "Content-Type": "multipart/form-data" },
data: payload.videoData,
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Videos/Add/`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit("isUploading");
commit('hideLoadingSpinner');
commit("toggleBlur");

if (responseResult.data.operation == 'success') {

commit("updateVideosData", responseResult.data.updatedVideosData);

commit("showModal", {
modalMessageEnglish: 'The new video has been added',
adminVerificationCase: 'AddNewVideo',
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to add video",
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-video-poster') {

commit("showModal", {
modalMessageEnglish: "The photo you selected is used for different video, please select a unique one",
informationalModal: true,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-video') {

commit("showModal", {
modalMessageEnglish: "The video you selected is used for different video, please select a unique one",
informationalModal: true,
});

return responseResult.data.operation;

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingVideoIndex: null,
});

}

} catch (error) {
throw new Error(error);
}

},


async updateVideoDataToDB({ commit, state }, payload) {

try {

commit('showLoadingSpinner');
commit("toggleBlur");
commit("isUploading");

const responseResult = await axios({
method: "post",  
headers: { "Content-Type": "multipart/form-data" },
data: payload.videoData,
credentials: "include",
withCredentials: true,
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Videos/Edit/${state.videoDatabaseId}`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit("isUploading");
commit('hideLoadingSpinner');
commit("toggleBlur");

if (responseResult.data.operation == 'success') {

commit("updateVideosData", responseResult.data.updatedVideosData);

commit("showModal", {
modalMessageEnglish: 'The changes have been saved',
informationalModal: true,
editingVideoIndex: state.editingVideoIndex
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete",
informationalModal: true,
editingVideoIndex: state.editingVideoIndex,
});

return responseResult.data.operation;


} else if (responseResult.data.operation == 'overwriting-other-video-poster') {

commit("showModal", {
modalMessageEnglish: "The photo you selected is used for different video, please select a unique one",
informationalModal: true,
editingVideoIndex: state.editingVideoIndex,
});

return responseResult.data.operation;

} else if (responseResult.data.operation == 'overwriting-other-post-video') {

commit("showModal", {
modalMessageEnglish: "The video you selected is used for different video, please select a unique one",
informationalModal: true,
editingVideoIndex: state.editingVideoIndex,
});

return responseResult.data.operation;

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingVideoIndex: null
});

}

} catch (error) {
throw new Error(error);
}

},


async deleteVideoFromDB({ commit, state }) {

try {

commit('showLoadingSpinner');

const responseResult = await axios({
method: "delete",  
url: process.env.VUE_APP_BASE_URL + `/Api/Content/Videos/Delete/${state.videoDatabaseId}`,
onUploadProgress: progressEvent => {
let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
commit("uploadPercent", percentCompleted);
},
});

commit('hideLoadingSpinner');

if (responseResult.data.operation == 'success') {

commit("updateVideosData", responseResult.data.updatedVideosData);

commit('hideModal');

} else if (responseResult.data.operation == 'no-permission') {

commit("showModal", {
modalMessageEnglish: "You don't have permission to delete",
informationalModal: true,
editingVideoIndex: state.editingVideoIndex,
});

} else {

commit("showModal", {
modalMessagePersian: 'مشکل در سیستم! لطفا مجدد تلاش کنید',
modalMessageEnglish: 'An error occurred! please try again',
appError: true,
editingVideoIndex: null,
});

}

} catch (error) {
throw new Error(error);
}

},

},

}

export default contentModule;